@charset "UTF-8";
@font-face {
    font-family: 'Heebo-Black';
    src: url("../fonts/static/Heebo-Black.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-Bold';
    src: url("../fonts/static/Heebo-Bold.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-ExtraBold';
    src: url("../fonts/static/Heebo-ExtraBold.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-ExtraLight';
    src: url("../fonts/static/Heebo-ExtraLight.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-Light';
    src: url("../fonts/static/Heebo-Light.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-Medium';
    src: url("../fonts/static/Heebo-Medium.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-Regular';
    src: url("../fonts/static/Heebo-Regular.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-SemiBold';
    src: url("../fonts/static/Heebo-SemiBold.ttf");
    font-style: normal;
}

@font-face {
    font-family: 'Heebo-Thin';
    src: url("../fonts/static/Heebo-Thin.ttf");
    font-style: normal;
}


a {
    cursor: pointer;
}

body {

    width: 100%;
    background-color: #fff;
    margin: 0;
    padding: 0;
    font-family: 'Heebo', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;

    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px);
  }

header {
    display: flex;
    flex-direction: column;
    background-color: #fff;

}

.pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;

}
i.fa-solid.fa-file-import {
    color: white;
    padding-right: 3px;
}

i.fa-solid.fa-arrow-right-from-bracket {
    color: black;
    padding-right: 5px;
    font-size: 13px;
}
ul.pagination li {
    padding: 0 5px;
}
ul.pagination li a {
    padding: 4px 10px;
    background: #000;
    color: white;
}
ul.pagination li a:hover, .ul.pagination li.pagination__link--active a {
    background: red;
}
ul.pagination li.pagination__link--active a {
    background: red;
}

.top-blocs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 12.3639px 15px;
    max-width: 100%;
    margin: 0 15px;
    min-width: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
}


.table-admin{
    overflow-x: auto;
    white-space: nowrap;
}


.blocks-content {
    display: flex;
    width: 100%;
    gap: 13rem;
    justify-content: space-between;
}

.bloc {
    width: 100px;
}

.bloc-1 {
    display: inline-block;
    vertical-align: top;
    margin-right: 0;
    width: 25%;
    position: relative;
    padding-right: 1.5%;
    color: #888787;
    font-weight: 300;
    font-style: normal;

}
 .bloc-1 image{
    width: 100%;
    max-width: 100%;
 }

 .bloc-2 {
    padding-left: .75%;
    padding-right: .75%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 41.66667%;
    color: #888787;
    font-weight: 300;
    font-style: normal;
}
.info img{
    width: 15px;
}
td.info-assurance{
   /*  display: flex; */
    gap: 2px;
    align-items: flex-start;
}
h1.Unauthorized--h1 {
    margin-top: 50px;
    font-size: 30px;
}
p.Unauthorized--p {
    font-size: 23px;
}
.bloc-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
    padding-left: 1.5%;
    display: inline-block;
    vertical-align: top;

    position: relative;
    width: 33.33333%;
    color: #888787;
    font-weight: 300;
    font-style: normal;
}
.social-icons {
    text-align: right;
    display: block!important;
    margin: 14px 0 0!important;
    text-align: right!important;
    float: none!important;
    top: 0!important;
    left: 0!important;
    width: calc(100% - 0px)!important;
    position: relative!important;
    height: auto!important;
    max-width: 100%!important;
    min-width: 25px!important;

}

.social-icons ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    float: right;
}

.social-icons ul li {
    margin-right: 7px;
    width: 24px!important;
    height: 24px!important;
    font-size: 16px!important;
    line-height: 24px!important;

}
.formRegister label input {
    padding-top: 0 !important;
}
.container-form input[type="text"]::-webkit-input-placeholder {
    color: black;
}
.social-icons ul li i {


    background-color: #141313 ;
    padding: 6px;
    font-size: 18px;
    color: #fff;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

}

.social-icons ul li i:hover{
    background-color: #141313;
    color: #fff;
    box-shadow: 0 0 10px grey;
    transform: scale(0.9);

}



 .social-icons ul li i:hover::after{
    content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: #000;
  transform: scale(0);
  transition: transform 0.3s ease;
 }

 .social-icons ul li i:hover::before{
    transform: scale(1);
 }

 .social-icons ul li i:hover::after{
    transform: scale(1);
 }

.div-i{
    margin-left: 12px;

}



nav {
    display: flex;
    align-items: center;
    margin-top: 5px;
    background: #000;


}

.name-document{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    font-size: 18px;

}


.logo-connexion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    text-align: center;
}

.logo-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #f4f1f1;
  }

.logo-circle img{
     max-width: 50% !important;
     height: auto;

}



.logo-connexion h1 {
    font-size: 45px;
    font-family: 'Heebo-Medium';
    font-weight: bold;
    margin-bottom: 1px;
}

.logo-connexion p {
    font-size: 16px;
    font-family: 'Heebo-Light';
    margin-bottom: 20px;
}




.form-container2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 150px;
}
.formReset{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    padding: 20px;
    background-color: white;
}

.formReset label {
    display: flex;
    font-family: 'Heebo-Medium';
    font-size: 15px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.formReset button[type="submit"] {

  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 1.1em;
  cursor: pointer;

}

.formReset label input {
    padding: 10px;
    width: 100%;
    margin-top: 5px;
    border: 0;
    background-color: rgb(242, 237, 237);
}

.formReset button[type="submit"] {
    background-color: #FF0000;
    margin-top: 10px;
    margin-left: 270px;
    padding: 10px 20px;
    width: 150px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-family: 'Heebo-Regular';
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}


.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 150px;
}

span.info {
    position: relative;
    cursor: pointer;
}

span.info:hover span.description{
    opacity: 1;
    visibility: visible;
}

span.description {
    position: absolute;
    white-space: pre-line;
    width: 141px;
    left: 18px;
    top: 12px;
    display: block;
    padding: 9px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.error{
    color: #f10c0c;
}

form.formRegister.modal-content {
    width: 100% !important;
    max-width: inherit !important;
}
.formRegister {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    padding: 20px 0;
    background-color: white;
    margin: 0 auto;
    max-width: 540px;

}



.formRegister label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.formRegister label input,.formRegister label textarea#message, .formRegister label select {
    padding-top: 0;
    padding: 10px;
    width: 100%;
    margin-top: 5px;
    font-size: 15px;
    line-height: 52px;
    height: 52px;
    border: 0;
    background-color: rgb(242, 237, 237);
}
.formRegister label textarea#message{
    height: 290px;
    line-height: 20px;
}

.formRegister label input:focus {

    border: none;
}

.formRegister button[type="submit"] {
    background-color: #FF0000;
    margin: 0 auto;
    padding: 10px 20px;
    width: 300px;
    color: #fff;
    border: none;
    font-size: 16px;
    font-family: 'Heebo-Regular';
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 18px;
}
.formRegister button[type="submit"]:disabled {
    cursor: not-allowed;
}

.spinner-check {
    display: flex;
    text-align: center;
    /* justify-items: center; */
    justify-content: center;
    align-items: center;
}

.spinner-check img {
    max-width: 150px;
}


.formRegister button:hover {
    background-color: #f10c0c;
}

.formRegister .btn-envoyer{

    width: 300px !important;

}

.formRegister a {
    margin-bottom: 40px;
    text-decoration: underline;

}

.btn-bottom {
    margin-bottom: 40px;
}




.reset-pwd a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
}


.eye-position, .eye-position3{
    position: relative;
}





.eye-position .eye{
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    right: 15px;
}





.eye-position3 .eye{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;

}




.eye i:hover{
filter: brightness(1.4);
}

i{
    color: #ff0000;
    font-size: 16px;

}



.title-pwd-reset{
    text-align: center;
    margin-top: 100px;
    line-height: 20px;


}

.title-pwd-reset h1{
    font-family: 'Heebo-Medium';
    font-size: 45px;

}


.title-pwd-reset p{
    font-family: 'Heebo-Light';
    font-size: 16px;

}




.menu-bloc {
    width: 25%;
}

.menu-bloc1 {
    width: 75%;
}



.nav-links ul {
    list-style: none;
    margin: 0;
    padding: 17px 15px !important;
    display: flex;
    gap: 4rem;

}

.nav-links ul li {


}

.nav-links ul li:last-child {
    margin-right: 0;
}

.nav-links a {
    text-decoration: none;
    color: #fff;
    transition: color 0.2s ease-in-out;
    font-size: 16px;
    font-family: 'Heebo-Light';
    margin-left: 20px;
    position: relative;

}

.nav-links a:hover {
    text-decoration: none;
    color: white;
}

.nav-links a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: #fff;
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: width 0.3s ease-in-out;

}

.nav-links a:hover::after{
    width: 100%;
}

.rv {
    background-color: #FF0000;
    padding: 20px 16px;
    text-align: center;
    font-size: 16px;
    font-family: 'Heebo-Light';
}

.rv a {
    text-decoration: none;
    color: #fff;
    position: relative;

}

.rv a:hover {

    text-decoration: none;
    color: #fff;
}

.rv a::before{
    content: '→';
    width: 30px;

  display: inline-block;
  font-size: 23px;
  transform: translateX(-20px);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.rv a:hover::before{
    transform: translateX(0);
  opacity: 1;
}







.logo1 {


}

.logo1 img{
    width: 230px;
}

.logo2 img {
    width: 150px;
}




.footer{

    width: 100%;
    z-index: 999;




}

footer {

    font-size: 14px;
    color: #fff;
    margin-top: 40px;
    display: flex;
    justify-content: center;


}

.footer-about,
.footer-address {
    flex: 1 1 300px;
}

.footer-about h2,
.footer-address h2 {
    font-family: 'Heebo-Medium';
    font-size: 20px;
    margin-bottom: 10px;
}

.footer-about p,
.footer-address p {
    margin: 0;
    line-height: 22px;
    font-size: 15px;
    font-family: 'Heebo-Regular';
}

.footer-address p:first-child {
    font-weight: bold;
    margin-bottom: 5px;
}

.section-top{
    width: 100%;
    background: #FF0000;
    text-align: center;
    padding: 10px 0;
    text-align: center;
}

.section-top a {
    font-size: 16px;
    font-family: 'Heebo-Bold';
    text-decoration: none;
    color: #fff;
    transition: color 0.2s ease-in-out;



}

div#footer-content {
    background: black;

}

.section-bottom{
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: white;
    background: #474747;

}

.section-bottom p {
    font-size: 14px;
    font-family: 'Heebo-Regular';
}
.section-middle{

    margin: 17px auto 77px;
    padding-top: 120px;
    display: flex;
    height: 257px;
   gap: 5rem;

}

.copyright {
    width: 100%;
    text-align: center;
    background: gray;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-top: 20px;

    font-weight: 300;
    font-size: 16px;
    color: #fff;
}
.container-header{
    max-width: 135rem;
    width: 85.5%;
    margin: 0 auto;
}
.container {
    max-width: 135rem;
    width: 85.5%;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    padding: 30px 0;
  }

  .welcome {
    width: 63%;
    font-size: 24px;
    line-height: .1px;
  }

  .welcome h3{
    font-family: 'Heebo-Medium';
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
  }

  .welcome p{
    font-family: 'Heebo-Light';
    font-size: 16px;
  }

  .buttons {
    width: 37%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    gap: 12px;
  }

  .buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #337ab7;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }



  .buttons .btn1 {
    background-color: #292929;
    width: auto;
    height: auto;
    border-radius: 36px;

    color: #fff;
    font-family: 'Heebo-Light';
    font-size: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .buttons .btns {
    background-color: #292929;
    width: 199px;
    height: 44px;
    border-radius: 36px;
    margin-right: 40px;
    color: #fff;
    font-family: 'Heebo-Light';
    font-size: 16px;


  }

  .link-profil{
cursor: pointer;
    position: relative;
  }

  .link-profil a{

    font-family: 'Heebo-Medium';
    font-size: 25px;
    white-space: nowrap;
  }
  a.link-profil--link{
    color: red;
    font-size: 16px;
    position: fixed;
    height: fit-content;
    width: fit-content;
    background-color: white;
    padding: 5px 12px;
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    border-radius: 5px;

    display: none;
}
  .link-profil:hover .link-profil--link {
    display: block;

  }
  .shadow-effect {
    position:relative;

}
.shadow-effect:before, .shadow-effect:after {

    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:5px;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.shadow-effect:after {
    right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
  .link-profil img{
    width: 45px;
    margin-right: 5px;
  }


  .separateur {
    border-top: 1px solid rgb(137, 134, 134);
    margin-left: 50px;
    width: 100%;

  }

  hr{
    width: 100%;
    margin-top: 150px;

  }

  .contrats h2, .ajout-contrat h2, .filtre h1, .filtre h2{


  }

  .filtre {

    margin-bottom: 150px;
  }
  .filtre h1{
    font-family: 'Heebo-Medium';
    font-size: 45px;
  }

  .filtre h2{
    font-family: 'Heebo-Medium';
    font-size: 25px;
  }

  section {

  }
  .ajout-contrat button{

    background-color: #FF0000;
    margin-top: 10px;
    margin-left: 90px;
    padding: 10px 20px;
    width: 150px;
    color: #fff;
    border: none;
    font-family: 'Heebo-Regular';
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

  }

  .container2{
    max-width: 135rem;
    width: 85.5%;
    margin: 0 auto;
  }


  .tableau {
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .tableau th, .tableau td {
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid rgb(201, 197, 197);
    text-align: left;
    padding-left: 0;


  }

  /* .tableau a {
    color: green !important;
  } */

  .tableau .detail{
    color: #FF0000 !important;
    float: left;
  }

  .social-icons button {
     background: none;
     height:30px;
     width:148px;
     border: 1px solid #000000;
     cursor: pointer;

    }

    .social-icons button:hover {
        color: #ffffff;
       }
       .social-icons button:hover i.fa-solid.fa-arrow-right-from-bracket {
        color: #ffffff;
       }
       button.log-out:hover {
        background-color: #FF0000;
        border: none;
    }
  .persistCheck {
     display: flex;
    }
    .persistCheck label{
         margin:0 0 0 10px;
        }

  .titre {
    font-family: 'Heebo-Bold';
    font-size: 25px;
  }


  th{
    font-family: 'Heebo-Bold';
    font-size: 14px;
  }

  td{
    font-family: 'Heebo-Regular';
    font-size: 12px;
  }

  .container2 .container-header {
    width: 100%;
}

  .container-profil{

    margin: 0 auto;
  }
  *{
    box-sizing: border-box;
}
.profil-form{
    width: 90%;
    margin: 0 auto;
    max-width: 540px;

}
.profil-form .formRegister{
    width: 100%;
    padding: 0;

}
.profil-form .formRegister label{
    font-size: 15px;
    line-height: 28px;
    padding-bottom: 17px;
}
.profil-form .formRegister input{
    height: 52px;
    line-height: 52px;
    margin: 0;

}

.profil-form .formRegister .eye-position .eye {
    right: 15px;
    left: inherit;
    top: 50%;
}
.profil-form .formRegister button[type="submit"] {

    margin-top: 30px;
}

.filtre {
    width: 90%;
}
.profil-form h1, .filtre h1{
    text-align: center;
    font-weight: 500;
    font-family: 'Heebo-Medium';
    font-size: 45px;
    line-height: 70px;

}

.zz-courtage {
    width: 100%;
}



.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 9999;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open h2 {
    color: black !important;
    font-size:25px;
    line-height: 70px;
    text-align: center;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .close{
    position: absolute;
    top: 12px;
    border: none;
    background: none;
    right: 15px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border-radius: 50%;
}


.loaderrr {
    font-size: 25px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #FF0000;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: fixed;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



.container-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    grid-column-gap: 50px;
    float: left;
    width: 30%;
   /* margin-left: 90px;*/
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .container-form select {
    width: 150px;
    height: 40px;
    padding: 5px;
    border: 0;
    background-color: #f2f2f2;

  }
  .container-form input {
    width: 150px;
    padding: 5px;
    height: 40px;
    border: 0;
    background-color: #f2f2f2;

  }

  .titles{
    display: flex;
    justify-content: space-between;
  }

  .detail{
    margin-bottom: 4px;
    color: #FF0000;
    /* text-decoration: underline; */
    padding: 8px;
    background: #ebeef1;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 5px 5px 5px -1px rgba(10, 99, 169, 0.16), -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
  }

  .download {
    margin-right: 5px;
  }

  .formRegister .fa-dowload{

    margin-left: 115px;


  }
  form.formRegister.modal-document {
    width: 100% !important;
    max-width: inherit !important;
}

/*  .tableau .fa-download{
    color: green !important;
}
 */





.logo-bloc{
    display: flex;
    gap: 11rem;
}
.menu-mobile{
    display: none;
}
.close-menu-mobile{
    display: none;
}


.search-users .column {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
}
.search-users .btn-7 {
margin-top: 0;

}


 /*  media query */

@media screen and (max-width: 1500 ) {



}
@media screen and (max-width: 1200px) {


    .blocks-content {
        gap: 1rem;
    }
    .nav-links ul {
        gap: 1rem;
    }
    .menu-bloc1 {
        width: 77%;
    }
    .rv {

        font-size: 14px;
    }
    .blocks-content {
        display: flex;
        width: 100%;
        flex-direction: column;

    }
    .logo-bloc {
        display: flex;
        gap: 6rem;
    }
    .bloc-3{
        width: auto;
        padding-left: 0;
    }
    .container {
        flex-direction: column;
    }

    .welcome {
        display: flex;
        gap: 2rem;
        align-items: baseline;
        width: 100%;
    }
    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
    }

    .buttons button {
        margin-left: 0;
    }
    .section-middle {
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 560px) {

    .blocks-content{
        flex-direction: column;
        gap: 2rem;
    }

    .form-container {
      display: block;
  }

    .formRegister {
        width: 90%;
    }

    .ReactModal__Content{
      width: 86% !important;
    }

    .ReactModalPortal .formRegister {
        width: 100%;
    }

    .filtre {
        width: 100%;
        overflow: hidden;
    }

    .buttons {
        flex-direction: column;
    }

    ul.pagination li {
        margin-bottom: 20px;
    }

    ul.pagination li a {
        display: block;
    }

    nav {
        flex-direction: column-reverse;
        margin-top: 20px;
    }
    .menu-bloc {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        text-align: center;
        justify-content: space-around;
        align-items: center;
    }
    .menu-bloc1 {
        width: 100%;
        display: none;;
    }
    .social-icons ul {
        float: inherit !important;
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .bloc-1 {
        padding-left: .75%;
       padding-right: .75%;
       display: inline-block;
       vertical-align: top;
       /* float: left; */
       position: relative;
       width: 60%;
       color: #888787;
       font-weight: 300;
       font-style: normal;
   }
    .div-i {
        margin-left: 0px;
    }
    .bloc-3{
        width: 100%;
    }

    .logo-bloc{
        display: flex;
        gap: 2rem;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo2 img {
        width: 100%;
    }
    .logo1 img {
        width: 100%;
    }
    .section-middle {
        height: auto;
        gap: 0rem;
        flex-direction: column;
    }

    .footer-about, .footer-address {
        flex: 1 1 175px;
    }
    .section-bottom {
        padding: 15px;
    }
    .nav-links ul {
        padding: 17px 15px !important;
        display: inherit;
        padding-left: 0 !important;
    }
    .nav-links a {
        margin-left: 0;
    }

    .logo-connexion h1 {
        font-size: 30px;
    }
    .menu-mobile{
        display: block;
    }
    .rv{
        width: 85%;
    }
    i.topIcon.fa-solid.fa-bars.menu-mobile {
        color: white;
        font-size: 28px;
    }

    .menu-mobile-humberger{
        width: 15%;
    }

    .close-menu-mobile{
       width: 15%;
        }
    .close-menu-mobile-icon{

    color: white;
    font-size: 28px;
    }
    .welcome h3{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
    }
    .container {
        flex-direction: column;
        gap: 1.5rem;
    }

    .welcome {
        width: 100%;
        display: flex;
        gap: 0rem;
        align-items: baseline;
        flex-direction: column;
    }

    .buttons .btn1 {
        width: 175px;
        margin-left: 0;
    }
    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .container-form {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
    .container-form select {
        width: 100%;

        padding: 5px;
        border: 0;
        background-color: #f2f2f2;
    }
    .container-form input {
        width: 100%;
        padding: 5px;
        border: 0;
        background-color: #f2f2f2;
    }
    .ReactModal__Overlay.ReactModal__Overlay--after-open h2 {
        font-size: 20px;
        line-height: 26px;
    }
    .formRegister button[type="submit"] {
        width: 100%;
        font-size: 15px;
    }
    .link-profil img {
        width: 18px;
  }
  .link-profil a {
      font-family: 'Heebo-Medium';
      font-size: 19px;
  }
  .profil-form h1{
    font-size: 30px;
    line-height: 50px;
    }
    .section-middle {
        display: flex;

    }
    .info-assurance {
        display: revert;
    }

    .search-users .column {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        align-items: center;
    }
    .search-users .btn-7 {
    margin-top: 0;
    }




    .contrats{
        overflow-x: scroll;


    }
    .contrats .tableau{

        white-space: nowrap;
        border-collapse: separate;


    }

    .container2{
        white-space: nowrap;
        overflow-x: auto;
    }

    .link-profil{
        white-space: nowrap;

    }

    .buttons .btn1{
        margin-right: 20px;
    }


}


.custom-btn {
    width: 130px;
    margin-top: 30px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
/* 7 */
i.fa-sharp.fa-solid.fa-magnifying-glass, i.fa-sharp.fa-solid.fa-arrows-rotate {
    color: white;
    font-size: 14px;
}




.btn-7 {
    background: linear-gradient(0deg, #ff0000 0%, #ff0000 100%);
      line-height: 42px;
      padding: 0;
      border: none;
      margin-right: 1px;
    }
    .btn-7 span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
    .btn-7:before,
    .btn-7:after {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      background: rgba(251,75,2,1);
      box-shadow:
       -7px -7px 20px 0px rgba(255,255,255,.9),
       -4px -4px 5px 0px rgba(255,255,255,.9),
       7px 7px 20px 0px rgba(0,0,0,.2),
       4px 4px 5px 0px rgba(0,0,0,.3);
      transition: all 0.3s ease;
    }
    .btn-7:before{
       height: 0%;
       width: 2px;
    }
    .btn-7:after {
      width: 0%;
      height: 2px;
    }
    .btn-7:hover{
      color: #fff;
    }
    .btn-7:hover:before {
      height: 100%;
    }
    .btn-7:hover:after {
      width: 100%;
    }
    .btn-7 span:before,
    .btn-7 span:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: #ff0000;
      box-shadow:
       -7px -7px 20px 0px rgba(255,255,255,.9),
       -4px -4px 5px 0px rgba(255,255,255,.9),
       7px 7px 20px 0px rgba(0,0,0,.2),
       4px 4px 5px 0px rgba(0,0,0,.3);
      transition: all 0.3s ease;
    }
    .btn-7 span:before {
      width: 2px;
      height: 0%;
    }
    .btn-7 span:after {
      height: 2px;
      width: 0%;
    }
    .btn-7 span:hover:before {
      height: 100%;
    }
    .btn-7 span:hover:after {
      width: 100%;
    }
    .btn-7 span:hover .fa-magnifying-glass{
       color: #fff;
      }

/* 5 */
.btn-5 {
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
    background: #FF0000;

  }
  .btn-5 span,.btn-5 i{
   color: white;
   font-size: 17px;
  }
  .btn-5:hover {
    color: #f0094a;
    background: transparent;
     box-shadow:none;
  }
  .btn-5:hover span, .btn-5:hover i{
    color: #f0094a;
    background: transparent;
     box-shadow:none;
  }
  .btn-5:before,
  .btn-5:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #f0094a;
    box-shadow:
     -1px -1px 5px 0px #fff,
     7px 7px 20px 0px #0003,
     4px 4px 5px 0px #0002;
    transition:400ms ease all;
  }
  .btn-5:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .btn-5:hover:before,
  .btn-5:hover:after{
    width:100%;
    transition:800ms ease all;
  }

  span.new {
    background-color: #67b373;
    PADDING: 6PX;
    border-radius: 11px;
    color: white;
}

span.en_attente {
    background-color: #e27a00;
    PADDING: 6PX;
    border-radius: 11px;
    color: white;
}
.red-color{
    color:red;
}
a.inviter {
    margin-left: 10px !important;
    color: green;
    margin-bottom: 4px;
    padding: 8px;
    background: #ebeef1;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 5px 5px 5px -1px rgba(10, 99, 169, 0.16), -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
    float: left;
}
span.active {
    background-color: #00c5ea;
    PADDING: 6PX;
    border-radius: 11px;
    color: white;
}
input#firstname::placeholder {
    font-style: normal !important;
    font-weight: 600 !important;
    color: black !important;
}
  .successMsg{
    text-align: center;
    color: green;
    font-family: 'Heebo-Light';
    font-size: 18px;
  }
  .profil-title {
    display: flex;
    align-items: center;
}

  .errorMsg{

    text-align: center;
    color: red;
    font-family: 'Heebo-Light';
    font-size: 18px;

  }

  .err{
    font-size: 12px;
  }

  .green{
    font-size: 25px;
    color: green;
  }

  i.fa-solid.fa-times.red {
    background: red;
    border-radius: 50px;
    color: #fff;
    height: 35px;
    left: 15px;
    position: relative;
    text-align: center;
    width: 50px;
    box-shadow: 5px 5px 5px -1px rgba(10, 99, 169, 0.16), -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
    float: left;
}



i.fa-solid.fa-times.red:before {
    position: relative;
    top: 4px;
    font-size: 18px;
}

i.fa-solid.fa-check.green-check {
    background: green;
    position: relative;
    left: 15px;
    border-radius: 50px;
    height: 37px;
    width: 50px;
    text-align: center;
    box-shadow: 5px 5px 5px -1px rgba(10, 99, 169, 0.16), -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
    float: left;
}



i.fa-solid.fa-check.green-check:before {
    color: #fff;
    font-size: 16px;
    position: relative;
    top: 4px;
}

  .red{

    font-size: 25px;
    color: red;

  }

  .white{
    color: #fff;
    margin-left: 10px;
    font-size: 16px;
  }

  #firstname::placeholder{
    font-family: 'Heebo-ExtraLight';
    font-style: italic;
  }

  #opt1{
    font-family: 'Heebo-ExtraLight';
    font-style: italic;

  }



  .reinit{
    color: #fff;
  }

  .reset-icon{
    color: #fff;
  }

  .bold-options {
    font-weight: bold;
  }


  .break {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;

}


.break:hover{
    white-space: normal;
    text-overflow: unset;
    white-space: normal;
}


.a-icon a, .a-icon-2 a{
    text-decoration: none;
}

.a-icon-2 a{
    margin: 0;
}

.a-icon {
    text-align: right;
}


li.a-icon-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  .input-wrapper input {
    flex: 1;
  }

  .input-wrapper i {
    position: absolute;
    right: 10px;
    color: green;
  }
